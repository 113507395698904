// var stickyHeader = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var inlineNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// var sidebarCards = false; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

// var visualAppealsLoadMore = false;

$("#menuMain").appendTo("#pageHeader .headerContent");
$(".homeIntroWrapper").prependTo(".homeFeatures");
$(".breadcrumbList").prepend('<li class="breadcrumbItem breadcrumbLink"><a href="/">Home</a></li>');

$(document).ready(function () {
    // footer structure
    const footerNewsletter = $('.footerBox[class*="ewsletter"]');
    footerNewsletter.after('<div id="pageFooterLinks"></div>');

    const pageFooterLinks = $("#pageFooterLinks");
    const footerColumn1 = $(".FooterColumn1");
    const footerColumn2 = $(".FooterColumn2");
    const footerColumn3 = $(".FooterColumn3");
    const footerColumn4 = $(".FooterColumn4");

    footerColumn1.appendTo(pageFooterLinks);
    footerColumn2.appendTo(pageFooterLinks);
    footerColumn3.appendTo(pageFooterLinks);
    footerColumn4.appendTo(pageFooterLinks);

    // move menu to between logo and main CTA
    function handleCallToActionButtons() {
        if ($(window).width() > 767) {
            // Check if the CTA section's parent is the header content or not
            if (!$("#pageHeader .mainCallToActionButtons").parent().is($("#pageHeader .headerContent .mainCallToAction"))) {
                // Else append the menu to the header content
                $("#pageHeader .mainCallToActionButtons").prependTo("#pageHeader .headerContent .mainCallToAction");
            }
        } else {
            // Check if the CTA section's parent is the menu main or not
            if (!$("#pageHeader .mainCallToActionButtons").parent().is($("#pageHeader #menuMain"))) {
                // Else insert the new element after the target element
                $("#pageHeader .mainCallToActionButtons").prependTo("#pageHeader #menuMain");
            }
        }
        var seen = {};
        $("#pageHeader .mainCallToActionButtons").each(function() {
            var txt = $(this).text();
            if (seen[txt]) {
                $(this).remove();
            }
            else {
                seen[txt] = true;
            }
        });
    }

    // Initial call to handle Call To Action buttons
    handleCallToActionButtons();
  
    // Attach the function to the window resize event
    $(window).on('resize', handleCallToActionButtons);

    // On the homepage:
    if ($('body').hasClass('homepage')) {
        // CTA: Block One
        const ctaOneImage = $(".homefeaturecategory-ctaoneimage");
        const ctaOneContent = $(".homefeaturecategory-ctaonecontent");
        ctaOneImage.wrapInner('<figure></figure>');
        ctaOneContent.wrapInner('<article></article>');
        ctaOneContent.appendTo(ctaOneImage);

        // CTA: Block Two
        const ctaTwoImage = $(".homefeaturecategory-ctatwoimage");
        const ctaTwoContent = $(".homefeaturecategory-ctatwocontent");
        ctaTwoImage.wrapInner('<figure></figure>');
        ctaTwoContent.wrapInner('<article></article>');
        ctaTwoContent.appendTo(ctaTwoImage);

        // CTA: Block Three
        const ctaThreeImage = $(".homefeaturecategory-ctathreeimage");
        const ctaThreeContent = $(".homefeaturecategory-ctathreecontent");
        ctaThreeImage.wrapInner('<figure></figure>');
        ctaThreeContent.wrapInner('<article></article>');
        ctaThreeContent.appendTo(ctaThreeImage);
    }

    // News Feed Carousel
    if ($(".homeFeed.NewsblogsFeed").length) {
        $(".homeFeed.NewsblogsFeed .feedList").slick({
            fade: true,
            dots: true,
            arrows: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 4000,
            adaptiveHeight: true,
            slidesToShow: 1,
            slidesToScroll: 1
        });
        //add button to each slide article 
        $(".homeFeed.NewsblogsFeed .feedItem").each(function () {
            const href = $(this).children("a").attr("href");
            const carouselDetail = $(this).find(".feedItemDetailsWrapper");

            $(carouselDetail).append(`<a href="${href}" class="cta-button cta-yellow">Read more</a>`)
        })
        $(".homeFeed.NewsblogsFeed").insertAfter(".homefeaturecategory-ctatwoimage")
    }
    // Lifestories Carousel
    if ($(".homeFeed.LifestoriesFeed").length) {
        $(".homeFeed.LifestoriesFeed .feedList").slick({
            fade: true,
            dots: true,
            arrows: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 4000,
            adaptiveHeight: true,
            slidesToShow: 1,
            slidesToScroll: 1
        });
        //add button to each slide article 
        $(".homeFeed.LifestoriesFeed .feedItem").each(function () {
            const href = $(this).children("a").attr("href");
            const carouselDetail = $(this).find(".feedItemDetailsWrapper");

            $(carouselDetail).append(`<a href="${href}" class="cta-button cta-yellow">Read more</a>`)
        })
        $(".homeFeed.LifestoriesFeed").appendTo(".homeFeature.homeBox1")
    }
    $(".listing .headerText .listingIntro").prependTo(".listing .contentBlockWrapper");
});

function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
  
function startCountUpAnimation(target, duration, end) {
    const increment = Math.ceil(end / (duration / 16));
    let current = 0;
  
    const interval = setInterval(() => {
        current += increment;
    
        if (current >= end) {
            clearInterval(interval);
            current = end;
        }
    
        target.textContent = formatNumberWithCommas(current);
    }, 16);
  }
  
function handleIntersection(entries, observer) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            const target = entry.target;
            const duration = 2000; // Set the desired duration for the animation
    
            // Retrieve the end value from the data attribute
            const end = parseInt(target.dataset.endValue.replace(/,/g, ""));
    
            // Start count-up animation
            startCountUpAnimation(target, duration, end);
        }
    });
  }
  
// Create an intersection observer
const observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 });

// Select all elements with the "countup" class
const targetElements = document.querySelectorAll('.countup');

// Iterate over the target elements
targetElements.forEach(targetElement => {
  // Store the end value in a data attribute
  targetElement.dataset.endValue = targetElement.textContent;

  // Observe each target element
  observer.observe(targetElement);
});

var monthNames, vacancies, endpoint, districts = ["Barking_Dagenham", "Barnet", "Bexley", "Brent", "Bromley", "Camden", "City", "Croydon", "Ealing", "Enfield", "Greenwich", "Hackney", "Hammersmith_Fulham", "Haringey", "Harrow", "Havering", "Hillingdon", "Hounslow", "Islington", "Kensington_Chelsea", "Kingston", "Lambeth", "Lewisham", "Merton", "Newham", "Redbridge", "Richmond", "Southwark", "Sutton", "Tower_Hamlets", "Waltham_Forest", "Westminster", "Wandsworth"];
$.each(districts, function(e, t) {
    $("#map-" + t).on("mouseenter", function() {
        $("#" + t).attr("class", "active")
    }), $("#map-" + t).on("mouseleave", function() {
        $("#" + t).attr("class", "")
    })
}), $("body").is(".PostCategory_career-opportunities-feed, .PostCategory_volunteer-opportunities-feed") && ($("body").hasClass("career-opportunities") && $('<h3>Vacancies</h3><ul class="work-opportunities--vacancies" />').insertBefore(".postContent .postFooterWrapper"), $("body").hasClass("volunteer-today") && $('<h3>Volunteers</h3><ul class="work-opportunities--volunteers" />').insertBefore(".postContent .postFooterWrapper"), monthNames = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"), vacancies = [], $.get(endpoint = "https://feeds.blueoctopus.co.uk/Feeds/hestia.tm?cid=11545&sky=9248c5a4-42c0-4447-aa3c-30100084e5e8&com=2", function(e) {
    $(e).find("item Vacancy").each(function() {
        var e = $(this),
            e = {
                ApplicationUrl: e.find("ApplicationUrl").text(),
                Title: e.find("Title").text(),
                Location: e.find("Location").text(),
                ExpiryDate: e.find("ExpiryDate").text(),
                VolunteerRole: e.find("VolunteerRole").text(),
                VacancyType: e.find("VacancyType").text()
            };
        vacancies.push(e)
    }), vacancies.sort(function(e, t) {
        return new Date(e.ExpiryDate) - new Date(t.ExpiryDate)
    }), vacancies.forEach(function(e) {
        var t = new Date(e.ExpiryDate),
            n = t.getDate(),
            o = t.getMonth(),
            t = t.getFullYear(),
            i = "",
            i = 1 == n || 21 == n || 31 == n ? "st" : 2 == n || 22 == n ? "nd" : 3 == n || 23 == n ? "rd" : "th",
            n = '<li><a href="' + e.ApplicationUrl + '" target="_blank"><div class="work-opportunities__position">' + e.Title + '</div><div class="work-opportunities__location">' + e.Location + '</div><div class="work-opportunities__expiry">' + n + "<sup>" + i + "</sup> " + monthNames[o] + " " + t + "</div></li>";
        "1" !== e.VacancyType && ("false" == e.VolunteerRole && $(n).appendTo("ul.work-opportunities--vacancies"), "true" == e.VolunteerRole) && $(n).appendTo("ul.work-opportunities--volunteers")
    })
}));

// Fundraiser profile picture and name- Move down the banner image
if ($('body').hasClass('fundraisers-tmhcc-christmas-social')) {
  $("img.mediaImage").insertBefore(".contentBlock");
  };